<template>
  <div class="sandbox">
    <div class="left">
      <a-card
        v-for="(item, index) in docModuleList"
        :key="index"
        :title="item.module"
        style="width: 100%; margin-top: 20px"
      >
        <p
          class="ff-list"
          :class="{ active: e.summary == leftActive }"
          v-for="(e, i) in item.docItems"
          :key="i"
          @click="selectButton(e, i)"
        >
          {{ e.summary }} {{ e.version }}
        </p>
      </a-card>
    </div>
    <div class="right">
      <div class="ff-content" v-if="docItems.summary == this.leftActive">
        <h2>
          <a-icon type="bars" style="margin-right: 10px" /><span>{{
            docItems.summary
          }}</span>
        </h2>
        <p>网关会对开发者的接口非业务调用错误做统一处理，返回码如下：</p>
        <a-table :columns="columnsParams" :data-source="docItems.codes" :pagination="false" bordered></a-table>
      </div>
    </div>
  </div>
</template>
<script>
const columnsParams = [
  {
    title: "code（返回码）",
    dataIndex: "code",
    width: 140,
    customRender: (value, row, index)=>{
      const obj = {
        children: value,
        attrs: {},
      };
      if (index === 1) {
        obj.attrs.rowSpan = 3
      }
      if (index > 1 && index < 4) {
        obj.attrs.rowSpan = 0
      }
      if(index === 4){
        obj.attrs.rowSpan = 6
      }
      if(index > 4 && index < 10){
        obj.attrs.rowSpan = 0
      }
      if(index === 10){
        obj.attrs.rowSpan = 8
      }
      if(index > 10 && index < 18){
        obj.attrs.rowSpan = 0
      }
      if(index === 18){
        obj.attrs.rowSpan = 20
      }
      if(index > 18 && index < 38){
        obj.attrs.rowSpan = 0
      }
      if(index === 39){
        obj.attrs.rowSpan = 4
      }
      if(index > 39 && index < 43){
        obj.attrs.rowSpan = 0
      }
      return obj
    }
  },
  {
    title: "msg（返回码描述）",
    dataIndex: "msg",
    width: 160,
    customRender: (value, row, index)=>{
      const obj = {
        children: value,
        attrs: {},
      };
      if (index === 1) {
        obj.attrs.rowSpan = 3
      }
      if (index > 1 && index < 4) {
        obj.attrs.rowSpan = 0
      }
      if(index === 4){
        obj.attrs.rowSpan = 6
      }
      if(index > 4 && index < 10){
        obj.attrs.rowSpan = 0
      }
      if(index === 10){
        obj.attrs.rowSpan = 8
      }
      if(index > 10 && index < 18){
        obj.attrs.rowSpan = 0
      }
      if(index === 18){
        obj.attrs.rowSpan = 20
      }
      if(index > 18 && index < 38){
        obj.attrs.rowSpan = 0
      }
      if(index === 39){
        obj.attrs.rowSpan = 4
      }
      if(index > 39 && index < 43){
        obj.attrs.rowSpan = 0
      }
      return obj
    }    
  },  
  {
    title: "sub_code（明细返回码）",
    dataIndex: "sub_code",
    width: 240
  },
  {
    title: "sub_msg（明细返回码描述）",
    dataIndex: "sub_msg",
    width: 240
  },
  {
    title: "解决方案",
    dataIndex: "programme",
  }
];

export default {
  data() {
    return {
      docModuleList: [{
        module: '文档专区',
        docItems: [
          {
            summary: '公共错误码',
            codes: [
              {
                code: '10000',
                msg: 'Success（接口调用成功）',
                sub_code: '',
                sub_msg: '',
                programme: '',
              },
              {
                code: '20000',
                msg: '服务不可用',
                sub_code: 'isp.unknown-error',
                sub_msg: '服务暂不可用（业务系统不可用）',
                programme: '稍后重试',
              },
              {
                code: '20000',
                msg: '服务不可用',
                sub_code: 'aop.unknow-error',
                sub_msg: '服务暂不可用（网关自身的未知错误）',
                programme: '稍后重试',
              },
              {
                code: '20000',
                msg: '服务不可用',
                sub_code: 'isp.service-not-available',
                sub_msg: '服务暂不可用',
                programme: '后重试',
              },
              {
                code: '20001',
                msg: '授权权限不足',
                sub_code: 'aop.invalid-auth-token',
                sub_msg: '无效的访问令牌',
                programme: '请刷新授权令牌或重新授权获取新的令牌',
                example: '88e68196d2359667f0dc8136e6c86803'
              },
              {
                code: '20001',
                msg: '授权权限不足',
                sub_code: 'aop.auth-token-time-out',
                sub_msg: '访问令牌已过期',
                programme: '请刷新授权令牌或重新授权获取新的令牌',
              },
              {
                code: '20001',
                msg: '授权权限不足',
                sub_code: 'aop.invalid-app-auth-token',
                sub_msg: '无效的应用授权令牌',
                programme: '请刷新应用授权令牌或重新授权获取新的令牌',
              },
              {
                code: '20001',
                msg: '授权权限不足',
                sub_code: 'aop.invalid-app-auth-token-no-api',
                sub_msg: '商户未授权当前接口',
                programme: '请重新授权获取新的应用授权令牌',
              },
              {
                code: '20001',
                msg: '授权权限不足',
                sub_code: 'aop.app-auth-token-time-out',
                sub_msg: '应用授权令牌已过期',
                programme: '请刷新应用授权令牌或重新授权获取新的令牌',
              },
              {
                code: '20001',
                msg: '授权权限不足',
                sub_code: 'aop.no-product-reg-by-partner',
                sub_msg: '商户未签约任何产品',
                programme: 'ISV代理调用的场景，请上线商户的服务窗',
              },
              {
                code: '40001',
                msg: '缺少必选参数',
                sub_code: 'isv.missing-method',
                sub_msg: '缺少方法名参数',
                programme: '请求参数里面必须要有method参数',
              },
              {
                code: '40001',
                msg: '缺少必选参数',
                sub_code: 'isv.missing-signature',
                sub_msg: '缺少签名参数',
                programme: '检查请求参数，缺少sign参数',
              },
              {
                code: '40001',
                msg: '缺少必选参数',
                sub_code: 'isv.missing-signature-type',
                sub_msg: '缺少签名类型参数',
                programme: '检查请求参数，缺少sign_type参数',
              },
              {
                code: '40001',
                msg: '缺少必选参数',
                sub_code: 'isv.missing-signature-key',
                sub_msg: '缺少签名配置',
                programme: '未上传公钥配置',
              },
              {
                code: '40001',
                msg: '缺少必选参数',
                sub_code: 'isv.missing-app-id',
                sub_msg: '缺少appId参数',
                programme: '检查请求参数，缺少app_id参数',
              },
              {
                code: '40001',
                msg: '缺少必选参数',
                sub_code: 'isv.missing-timestamp',
                sub_msg: '缺少时间戳参数',
                programme: '检查请求参数，缺少timestamp参数',
              },
              {
                code: '40001',
                msg: '缺少必选参数',
                sub_code: 'isv.missing-version',
                sub_msg: '缺少版本参数',
                programme: '检查请求参数，缺少version参数',
              },
              {
                code: '40001',
                msg: '缺少必选参数',
                sub_code: 'isv.decryption-error-missing-encrypt-type',
                sub_msg: '解密出错, 未指定加密算法',
                programme: '检查参数，缺少encrypt_type参数',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-parameter',
                sub_msg: '参数无效',
                programme: '检查参数，格式不对、非法值、越界等',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.upload-fail',
                sub_msg: '文件上传失败',
                programme: '文件写入失败，重试',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-file-extension',
                sub_msg: '文件扩展名无效',
                programme: '检查传入的文件扩展名称，目前支持格式：csv,txt,zip,rar,gz,doc,docx,xls,xlsx,pdf,bmp,gif,jpg,jpeg,png',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-file-size',
                sub_msg: '文件大小无效',
                programme: '检查文件大小，目前支持最大为：50MB ',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-method',
                sub_msg: '不存在的方法名',
                programme: '检查入参method是否正确 ',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-format',
                sub_msg: '无效的数据格式',
                programme: '检查入参format，目前只支持json和xml 2种格式 ',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-signature-type',
                sub_msg: '无效的签名类型',
                programme: '检查入参sign_type,目前只支持RSA,RSA2,HMAC_SHA1',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-signature',
                sub_msg: '无效签名',
                programme: '1.公私钥是否是一对 2.检查公钥上传是否与私钥匹配 3.存在中文需要做urlencode 4.签名算法是否无误',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-encrypt-type',
                sub_msg: '无效的加密类型',
                programme: '检查入参encrypt_type，目前只支持AES',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-encrypt',
                sub_msg: '解密异常',
                programme: '重试',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-app-id',
                sub_msg: '无效的appId参数',
                programme: '检查入参app_id，app_id不存在，或者未上线',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-timestamp',
                sub_msg: '非法的时间戳参数',
                programme: '时间戳参数timestamp非法，请检查格式需要为"yyyy-MM-dd HH:mm:ss"',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-charset',
                sub_msg: '字符集错误',
                programme: '请求参数charset错误，目前支持格式：GBK,UTF-8',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.invalid-digest',
                sub_msg: '摘要错误',
                programme: '检查请求参数，文件摘要参数必填',
              },

              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.decryption-error-not-valid-encrypt-type',
                sub_msg: '解密出错，不支持的加密算法',
                programme: '检查入参encrypt_type，目前只支持AES',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.decryption-error-not-valid-encrypt-key',
                sub_msg: '解密出错, 未配置加密密钥或加密密钥格式错误',
                programme: '没有配置加密密钥',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.decryption-error-unknown',
                sub_msg: '解密出错，未知异常',
                programme: '重试',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.missing-signature-config',
                sub_msg: '验签出错, 未配置对应签名算法的公钥或者证书',
                programme: '没有配置应用公钥',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.not-support-app-auth',
                sub_msg: '本接口不支持第三方代理调用',
                programme: '本接口不支持第三方代理调用',
              },
              {
                code: '40002',
                msg: '非法的参数',
                sub_code: 'isv.suspected-attack',
                sub_msg: '可疑的攻击请求',
                programme: '',
              },
              {
                code: '40004',
                msg: '业务处理失败',
                sub_code: '对应业务错误码，明细错误码和解决方案请参见具体的API接口文档',
                sub_msg: '',
                programme: '',
              },
              {
                code: '40006',
                msg: '权限不足',
                sub_code: 'isv.insufficient-isv-permissions',
                sub_msg: 'ISV权限不足',
                programme: '请检查配置的账户是否有当前接口权限',
              },
              {
                code: '40006',
                msg: '权限不足',
                sub_code: 'isv.insufficient-user-permissions',
                sub_msg: '用户权限不足',
                programme: '代理的商户没有当前接口权限',
              },
              {
                code: '40006',
                msg: '权限不足',
                sub_code: 'isv.route-no-permissions',
                sub_msg: '用户权限不足',
                programme: '没有当前接口权限',
              },
              {
                code: '40006',
                msg: '权限不足',
                sub_code: 'isv.access-forbidden',
                sub_msg: '用户权限不足',
                programme: '无权访问（接口被禁用）',
              },

            ]
          }
        ]
      }],
      docItems: {},
      columnsParams,
      leftActive: "公共错误码",
    };
  },
  mounted() {
     this.docItems = this.docModuleList[0].docItems[0]
     let i = 0 
     this.docItems.codes.forEach((res, index)=>{
      res.key =  index
     });
  },
  methods: {
    selectButton(e, i) {
      this.docItems = e
      this.leftActive = e.summary
      window.scrollTo(0, 0)
    },
  },
};
</script>
<style lang="scss">
.sandbox {
  width: 1200px;
  height: calc(100% - 60px);
  margin: 0 auto;
  display: flex;
  .left {
    width: 240px;
    padding-top: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    .ff-list {
      cursor: pointer;
      &.active {
        color: #1890ff;
      }
    }
  }
  .right {
    width: calc(100% - 240px);
    border-left: 1px solid #eee;
    padding-top: 20px;
    padding-left: 20px;
    .ff-img{
      width: 100%;
    }
    h2{
      margin: 20px 0;
    }
    p{
      margin: 20px 0;
    }
    pre{
      width: 100%;
      margin: 20px 0;
      word-wrap: break-word;
      font-size: 12px;
      background: #eee;
      padding: 10px 0;
    }
    .xian {
      width: 100%;
      height: 1px;
      background: #eee;
      margin: 20px 0;
    }
    .xian-title {
      position: relative;
      font-size: 20px;
      margin: 20px 0;
      span {
        position: relative;
        z-index: 2;
        background: #fff;
        padding: 0 5px;
        margin: 0 20px;
      }
      &::before {
        content: "";
        msg: block;
        width: 100%;
        height: 1px;
        background: #eee;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .ff-set {
    margin: 20px 0;
  }
}
</style>
